<template>
<div class="home">
    <section class="hero is-light">
    <div class="hero-body">
      <div class="container">
      <h4 class="title">
      <div class="my-h1"> Life happens. We will buy your property.</div>
      <ul>
        <li class="my-h2">Sell as-is. No need to do fixes before selling.</li>
        <li class="my-h2">We handle all closing cost and overhead.</li>
        <li class="my-h2">No cost for real estate commission.</li>
        <li class="my-h2">Protect your credit rating.</li>
        <li class="my-h2">Get cash in your pocket.</li>
        <li class="my-h2">Sell fast usually within 30 days or sooner.</li>        
        <li class="my-h2">Don't bother delaying to schedule repair work before selling.</li>
        <li class="my-h2">No need for people walking through your home at any time.</li>        
        <li class="my-h1">Move on with your life!</li>
      </ul>
      <a href="#entertext" class="btn btn-warning btn-lg" role="button">Fill out form</a>
      </h4>
      <h4 class="title">
        We are here to help. <br />
        Contact us at info@needtosell.net or enter form below.
      </h4>
      </div>
    </div>
    </section>

    <div class="row justify-content-center">
      <div class="col-md-6">
          <a id="entertext" href="#"></a>
          <h3 class="my-h3 text-center">Please Enter Your Name and Property Information</h3>
          <label class="text-danger">{{ errorfield }}</label>
          <form @submit.prevent="handleSubmitForm">
              <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="seller.name" required>
              </div>

              <div class="form-group">
                  <label>Email</label>
                  <input type="email" class="form-control" v-model="seller.email" required>
              </div>

              <div class="form-group">
                  <label>Phone</label>
                  <input type="text" class="form-control" v-model="seller.phone" required>
              </div>
              <div class="form-group">
                  <label>Property Address</label>
                  <input type="textarea" class="form-control" v-model="seller.assetaddress" required>
              </div>
              <div class="form-group">
                  <label>Property Type</label><br />
                  <b-form-select v-model="seller.assettype" :options="options"></b-form-select><br /><br />
              </div>              
              <div class="form-group text-center">
                  <button class="btn btn-danger btn-block">Submit</button><br /><br /><br /><br /><br />
              </div>
          </form>
      </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

export default {
  name: 'home',
  data() {
      return {
          seller: {
              name: '',
              email: '',
              phone: '',
              assetaddress: '',
              assettype:'',
              clientip:'0.0.0.0',
          },
          errorfield:"",

          
          selected:"",
          options: [
            { value: "", text: 'Please select an option' },
            { value: 'single', text: 'Single residential unit' },
            { value: 'multi', text: 'Multi residential dwelling unit' },
            { value: 'storage', text: 'Storage unit' },
            { value: 'commercial', text: 'Commercial unit' },
            { value: 'other', text: 'Other' },
          ]
      }
  },
  methods: {
      async handleSubmitForm() {
          // await fetch("https://checkip.amazonaws.com/", {mode: 'no-cors'}).then(res => res.text()).then(data => {this.seller.clientip = data; console.log("Client IP:", data)
          // })
          const configruntime = require('../configruntime');
          const port = configruntime.apiport;
          let apiURL = 'https://needtosell.net/api/create-seller';
          if (configruntime.environment == 'dev') {
              apiURL = 'http://localhost:' + port + '/api/create-seller';
          }
          // console.log("using apiURL:", apiURL);
          this.errorfield = "";
          axios.post(apiURL, this.seller).then(() => {
            this.$router.push('/confirm')
            this.seller = {
              name: '',
              email: '',
              phone: '',
              assetaddress: '',
              assettype:'',
              clientip:'0.0.0.0'
            }
          }).catch(error => {
            if (error.response) {
              this.errorfield = error.response.data;
            }
          });
      }
  }
}
</script>
<style lang="scss" scoped>
  .org-description {
    margin-top: 50px;
  }
    .hero {    
    text-align: center;
    background-image: url('../assets/beachwalk2-50.png'); 
    background-size: cover;
    background-position: center;
  
    // background-repeat: repeat;
    // height: 400px;
  }
  .hero-body .title {
    // text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    padding: 40px 0 20px 0;
    font-size: 60px;
 
  }
  .subtitle {
    // text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
    font-size: 30px;

  }
  .my-h1 {
    font-size:60px;
  }
  .my-h2 {
    font-size:40px;
  }
  .my-h3 {
    font-size:30px;
  }
  .button-block {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: absolute;
    bottom: -150px;
    .button {
      margin-right: 50px;
      padding-left: 50px;
      padding-right: 50px;
    }
    .welcome {
      width: 400px;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .is-xl {
    font-size: 1.7rem;
  }
</style>