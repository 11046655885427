<template>

    <!-- <b-navbar-brand href="/"><img src="https://reisolver.s3.amazonaws.com/nts.png" width="280" height="250"></b-navbar-brand> -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark static-top">
  <div class="container">
    <a class="navbar-brand" href="/">
      <img src="https://reisolver.s3.amazonaws.com/nts.png" alt="..." height="250" width="280">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto">
        <!-- <li class="nav-item">
          <a class="nav-link" href="/about">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/list">List</a>
        </li> -->
        <li class="nav-item">
          <b-navbar toggleable="lg" type="dark" variant="info">
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
       <!-- Right aligned nav items -->
      <b-navbar-nav class="justify-content-end">
      </b-navbar-nav>
      </b-collapse>
  </b-navbar>
</li>
      </ul>
    </div>
  </div>
</nav>
</template>
<script>
export default {
  name: 'Nav',
  methods: {
  // Log the user in
  login() {
    this.$auth.loginWithRedirect();
  },
  // Log the user out
  logout() {
    this.$auth.logout({
      returnTo: window.location.origin
    });
  }
  }
}
</script>
<style lang="scss" scoped>
 .navbar.navbar-dark.bg-dark{
    // background-color: #ffb300!important;
    // background-color: #81D4FA!important;
    background-color: #63ade9!important;
 }
.navbar-dark .navbar-nav .nav-link{
    color:black!important
}
</style>